"use client";
import { FaWhatsapp, FaEnvelope } from "react-icons/fa"; // Import icons from react-icons
import FloatingForm from "@/components/FloationForm";

const FloatingContactButtons = () => {
  // State to manage popup visibility

  return (
    <div>
      {/* WhatsApp Button */}
      <a
        href="https://web.whatsapp.com/send?phone=+919619004000" // Replace 'your_number' with your WhatsApp number
        target="_blank"
        rel="noopener noreferrer"
        className="fixed bottom-20 left-4 bg-green-500 text-white p-4 rounded-full shadow-lg hover:bg-green-600 transition-colors duration-300 hidden md:block"
        aria-label="WhatsApp"
      >
        <FaWhatsapp className="w-6 h-6" />
      </a>
      <a
        href="https://wa.me/9619004000" // Replace 'your_number' with your WhatsApp number
        target="_blank"
        rel="noopener noreferrer"
        className="fixed bottom-20 left-4 block md:hidden bg-green-500 text-white p-4 rounded-full shadow-lg hover:bg-green-600 transition-colors duration-300"
        aria-label="WhatsApp"
      >
        <FaWhatsapp className="w-8 h-8" />
      </a>

      <FloatingForm />
    </div>
  );
};

export default FloatingContactButtons;
