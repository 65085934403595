"use client";
import { useState } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { FaEnvelope } from "react-icons/fa"; // Import icons from react-icons

// Define the schema with zod for validation
const schema = z.object({
  name: z.string().min(1, { message: "Name is required" }),
  email: z
    .string()
    .email({ message: "Invalid email address" })
    .min(1, { message: "Email is required" }),
  message: z.string().min(1, { message: "Message is required" }),
});

const FloationForm = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [isSending, setIsSending] = useState(false); // State for form submission
  const [message, setMessage] = useState(""); // State for success/error messages

  // Set up react-hook-form with zod validation
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  // Function to toggle popup visibility
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  // Form submission handler
  const onSubmit = async (data) => {
    setIsSending(true); // Start sending
    setMessage(""); // Reset message state

    try {
      const response = await fetch("/api/sendmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        setMessage("Email sent successfully. Check your inbox.");
        reset(); // Reset form fields
      } else {
        throw new Error("Failed to send email");
      }
    } catch (error) {
      setMessage(error.message);
    } finally {
      setIsSending(false); // Done sending
    }
  };

  return (
    <>
      {/* Email Button to Open Popup */}
      <button
        onClick={togglePopup}
        className="fixed bottom-40 lg:bottom-36 left-4 bg-blue-500 text-white p-4 rounded-full shadow-lg hover:bg-blue-600 transition-colors duration-300"
        aria-label="Email"
      >
        <FaEnvelope className="w-8 h-8 lg:w-6 lg:h-6" />
      </button>

      {/* Popup Form */}
      <div
        className={`fixed z-50 flex items-start transition-all duration-500 ease-in-out ${
          showPopup ? "opacity-100 scale-100" : "opacity-0 scale-0"
        } ${!showPopup && "pointer-events-none"}`}
        style={{
          bottom: "210px", // Adjust this value to align with the button
          left: "50px", // Adjust this value to place the form beside the button
        }}
      >
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-[22rem] lg:w-full transform transition-transform duration-500 ease-in-out">
          <h2 className="text-2xl font-bold mb-4 text-center">Contact Us</h2>

          {/* Handle success or error message */}
          {message && (
            <p className="text-white bg-green-500 p-2 my-2 rounded-lg">
              {message}
            </p>
          )}

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex gap-5">
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block text-gray-700 font-medium mb-2"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  {...register("name")}
                  className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                  placeholder="Enter your name"
                />
                {errors.name && (
                  <p className="text-red-500">{errors.name.message}</p>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-gray-700 font-medium mb-2"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  {...register("email")}
                  className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                  placeholder="Enter your email"
                />
                {errors.email && (
                  <p className="text-red-500">{errors.email.message}</p>
                )}
              </div>
            </div>
            <div className="mb-4">
              <label
                htmlFor="message"
                className="block text-gray-700 font-medium mb-2"
              >
                Message
              </label>
              <textarea
                id="message"
                rows="4"
                {...register("message")}
                className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                placeholder="Enter your message"
              ></textarea>
              {errors.message && (
                <p className="text-red-500">{errors.message.message}</p>
              )}
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600"
                disabled={isSending}
              >
                {isSending ? "Sending..." : "Send Message"}
              </button>
              <button
                type="button"
                className="ml-4 text-gray-600 hover:underline"
                onClick={togglePopup}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default FloationForm;
